<template>
    <div width="40em" 
        height="300px" 
                
        class="d-flex mx-auto mt-12 align-center justify-center"
        elevation="1"
        
    >
        <v-icon class="mx-2" x-large>mdi-layers-off</v-icon>
        <div class="text-h4 mx-2 grey--text text--darken-1">No Data</div>
    </div>
</template>

<script>
export default {
    name: 'NoDataComponent',
    data: () => ({

    }),
}
</script>

<style>

</style>